<template>
    <div class="document-card-footer">
        <div
            :class="[
                'document-card-footer__statistics-wrapper',
                { 'document-card-footer__statistics-wrapper--grid': enableThreeColumnsGrid },
            ]"
        >
            <pui-icon
                icon-name="visible"
                size="2.4rem"
            />
            <span class="document-card-footer__action-count mr-2">{{ document.viewCount }}</span>
            <button-like-toggle :document="document" />
            <span class="document-card-footer__action-count mr-2">{{ document.likeCount }}</span>
        </div>

        <div
            :class="[
                'document-card-footer__learning-info',
                { 'document-card-footer__learning-info--grid': enableThreeColumnsGrid },
            ]"
        >
            <div
                data-cy="cardDocumentPublished"
                class="mr-2 document-card-footer__text"
            >
                {{ $t('document.publishFilter') }}: {{ formatDate(document.issuedDate) }}
            </div>
            <div
                data-cy="cardDocumentLearningNo"
                class="mr-2 document-card-footer__text"
            >
                {{ $t('document.learningNo') }} {{ document.documentNumber }}
            </div>
            <div
                v-if="document.publishedDate"
                class="mr-2 document-card-footer__text"
            >
                {{ `${$t('document.published')}:` }} {{ formatDate(document.publishedDate) }}
            </div>

            <pui-link
                class="synergy-link"
                v-if="document.cases[0].synergiCaseId"
                :title="`${$t('document.form.synergi.caseId')} #${document.cases[0].synergiCaseId}`"
                @click="openSynergyLink(document.cases[0].synergiCaseId)"
            />
            <div
                v-else
                data-cy="cardDocumentSinergyLifeNo"
                class="document-card-footer__text"
            >
                {{ `${$t('document.form.synergi.caseId')}  -` }}
            </div>
        </div>

        <div
            data-cy="cardDocumentSinergyLifeNo"
            :class="[
                'document-card-footer__status',
                'document-card-footer__text',
                { 'document-card-footer__status--grid': enableThreeColumnsGrid },
            ]"
        >
            <span class="ml-1">{{ getReviewStatus }}</span>
            <pui-icon
                :icon-color="getReviewStatusColors(document.reviewStatus).backgroundColor"
                icon-name="circle"
                size="16px"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonLikeToggle from '@/components/button-like/button-like-toggle.vue';
import { Document } from '@/models';
import { formatDate } from '@/utils/helpers/formatters';
import { getreviewStatusBadgeColors } from '@/utils/helpers/badge-colors';
import { DocumentStatus } from '@/models/document';

@Component({
    name: 'document-card-footer',
    components: { ButtonLikeToggle },
})

export default class DocumentCardFooter extends Vue {
    @Prop({ type: Boolean, default: false })
    private readonly enableThreeColumnsGrid!: boolean;

    @Prop(Object) readonly document!: Document

    private VUE_APP_SYNERGY_LIFE_URL: string | undefined = process.env.VUE_APP_SYNERGY_LIFE_URL;

    private status: any ='';
    private get getReviewStatus(): any{
        switch(this.document.reviewStatus){
        case DocumentStatus.Draft:
            this.status = this.$t('document.draft');
            break;
        case DocumentStatus.Published:
            this.status = this.$t('document.publishFilter');
            break;
        case DocumentStatus.PendingTranslation:
            this.status = this.$t('document.pendingTranslation');
            break;
        case DocumentStatus.NotRelevant:
            this.status = this.$t('document.notRelevent');
            break;
        case DocumentStatus.Verified:
            this.status = this.$t('document.verified');
            break;
        default :
            this.status = this.document.reviewStatus;
            break;
        }
        return this.status;
    }

    private openSynergyLink(synergyId: string): void {
        window.open(`${this.VUE_APP_SYNERGY_LIFE_URL}/${synergyId}`, '_blank');
    }

    formatDate = formatDate;
    getReviewStatusColors = getreviewStatusBadgeColors;
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.document-card-footer {
    display: flex;
    align-items: center;
    gap: 2.3rem;
    font-size: 1.4rem;
    color: @warm-grey;
}

.document-card-footer__text {
    color: @dark-grey-50;
}

.document-card-footer__statistics-wrapper {
    display: flex;
    align-items: center;

    &--grid {
        flex: 1 0;
        width: 25%;
    }
}
.document-card-footer__action-count {
    color: #00426C;
    margin-left: 5px;
    font-size: 1.4rem;
}

.document-card-footer__learning-info {
    display: flex;
    align-items: center;

    &--grid {
        flex: 2 1;
        width: 50%;
    }
}

.document-card-footer__status {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-grow: 1;

    &--grid {
        flex: 1 0;
        width: 25%;
    }
}

.synergy-link {
    font-size: 1.4rem;
}
</style>
