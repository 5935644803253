import BaseService from '@/utils/base-service';
import { ApiResponse, ApiResponseList, Document, Pagination } from '@/models';
import { DocumentExportParams, ReviewStatus } from '@/models/document';

interface GetDocumentsResponse extends Pagination, ApiResponseList<Document> {}

/**
 * @name DocumentService
 * @description This class is used to query API for data related to App needs,
 */
export class DocumentService extends BaseService {
    constructor() {
        super('document');
    }

    public async getDocuments(config?: any): Promise<ApiResponse<GetDocumentsResponse>> {
        return (await this.api.get<ApiResponse<GetDocumentsResponse>>(`${this.endpoint}/all`, config)).data;
    }

    public async getDocument(paramValue: string, param = 'DocumentNumber'): Promise<ApiResponse<Document>> {
        return (await this.api.get<ApiResponse<Document>>(`${this.endpoint}?${param}=${paramValue}`)).data;
    }

    public async deleteDocument(id: number): Promise<void> {
        return (await this.api.delete<any>(`${this.endpoint}?Id=${id}`)).data;
    }

    public async addDocument(document: Document): Promise<ApiResponse<Document>> {
        return (await this.api.post<ApiResponse<Document>>(`${this.endpoint}`, document)).data;
    }

    public async updateDocument(document: Document): Promise<ApiResponse<Document>> {
        return (await this.api.put<ApiResponse<Document>>(`${this.endpoint}`, document)).data;
    }

    public async updateReviewStatus(reviewStatus: ReviewStatus): Promise<ApiResponse<Document>> {
        return (await this.api.put<ApiResponse<Document>>('reviewstatusupdate', reviewStatus)).data;
    }

    public async getCreators(): Promise<ApiResponse<any>> {
        return (await this.api.get<ApiResponse<any>>('creator/all')).data;
    }

    public async getEditors(): Promise<ApiResponse<any>> {
        return (await this.api.get<ApiResponse<any>>('editor/all')).data;
    }

    public async exportDocument(documentNumber: string, params: DocumentExportParams): Promise<any> {
        return (await this.api.post<any>(
            `${this.endpoint}/${documentNumber}/export`,
            params,
            {
                responseType: 'blob'
            }
        )).data;
    }
}
