var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"document-card-footer"},[_c('div',{class:[
            'document-card-footer__statistics-wrapper',
            { 'document-card-footer__statistics-wrapper--grid': _vm.enableThreeColumnsGrid },
        ]},[_c('pui-icon',{attrs:{"icon-name":"visible","size":"2.4rem"}}),_c('span',{staticClass:"document-card-footer__action-count mr-2"},[_vm._v(_vm._s(_vm.document.viewCount))]),_c('button-like-toggle',{attrs:{"document":_vm.document}}),_c('span',{staticClass:"document-card-footer__action-count mr-2"},[_vm._v(_vm._s(_vm.document.likeCount))])],1),_c('div',{class:[
            'document-card-footer__learning-info',
            { 'document-card-footer__learning-info--grid': _vm.enableThreeColumnsGrid },
        ]},[_c('div',{staticClass:"mr-2 document-card-footer__text",attrs:{"data-cy":"cardDocumentPublished"}},[_vm._v(" "+_vm._s(_vm.$t('document.publishFilter'))+": "+_vm._s(_vm.formatDate(_vm.document.issuedDate))+" ")]),_c('div',{staticClass:"mr-2 document-card-footer__text",attrs:{"data-cy":"cardDocumentLearningNo"}},[_vm._v(" "+_vm._s(_vm.$t('document.learningNo'))+" "+_vm._s(_vm.document.documentNumber)+" ")]),(_vm.document.publishedDate)?_c('div',{staticClass:"mr-2 document-card-footer__text"},[_vm._v(" "+_vm._s(`${_vm.$t('document.published')}:`)+" "+_vm._s(_vm.formatDate(_vm.document.publishedDate))+" ")]):_vm._e(),(_vm.document.cases[0].synergiCaseId)?_c('pui-link',{staticClass:"synergy-link",attrs:{"title":`${_vm.$t('document.form.synergi.caseId')} #${_vm.document.cases[0].synergiCaseId}`},on:{"click":function($event){return _vm.openSynergyLink(_vm.document.cases[0].synergiCaseId)}}}):_c('div',{staticClass:"document-card-footer__text",attrs:{"data-cy":"cardDocumentSinergyLifeNo"}},[_vm._v(" "+_vm._s(`${_vm.$t('document.form.synergi.caseId')} -`)+" ")])],1),_c('div',{class:[
            'document-card-footer__status',
            'document-card-footer__text',
            { 'document-card-footer__status--grid': _vm.enableThreeColumnsGrid },
        ],attrs:{"data-cy":"cardDocumentSinergyLifeNo"}},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.getReviewStatus))]),_c('pui-icon',{attrs:{"icon-color":_vm.getReviewStatusColors(_vm.document.reviewStatus).backgroundColor,"icon-name":"circle","size":"16px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }