import { Category, GetDocumentsQuery } from '@/models';
import { Filters, SeverityAndRisk } from '@/models/filters';
import { isArray, startCase } from 'lodash';
import Permissions from '@/utils/permissions';
import { DocumentStatus } from '@/models/document';

const permissions: Permissions = new Permissions();

const isSuperAdmin = (): boolean => {
    return permissions.hasSuperReviewAccess();
}
const isContentReviewer = (): boolean => {
    return permissions.hasReviewAccess();
}

export const FILTERS = {
    SEVERITY: 'Severity',
    RISK: 'Risk',
    HAZARD: 'Hazard',
    LOCATION: 'Location',
    CONSEQUENCE: 'Consequence',
    CATEGORY: 'Category',
    FLEET: 'Fleet',
    EDITOR: 'Editor',
    REVIEWSTATUS: 'ReviewStatus',
};

const filtersToCount = [
    FILTERS.SEVERITY,
    FILTERS.RISK,
    FILTERS.HAZARD,
    FILTERS.LOCATION,
    FILTERS.CONSEQUENCE,
    FILTERS.CATEGORY,
    FILTERS.FLEET,
    FILTERS.EDITOR,
    FILTERS.REVIEWSTATUS,
];

export const riskOptions: Category[] = [
    {
        name: 'notAvailableApplicable' as string,
        id: 'NotApplicable',
        children: [],
    },
    {
        name: 'low' as string,
        id: 'Low',
        children: [],
    },
    {
        name: 'medium' as string,
        id: 'Medium',
        children: [],
    },
    {
        name: 'severe' as string,
        id: 'Severe',
        children: [],
    },
];


export const severityOptions: Category[] = [
    ...riskOptions,
    {
        name: 'observationNearHit' as string,
        id: 'ObservationNearHit',
        children: [],
    },
];

export const riskAndSeverityOptions: Category[] = [...severityOptions];

export const translateRiskAndSeverityOptions = (severityKey: string): string => {
    return riskAndSeverityOptions.find(o => o.id === severityKey)?.name ?? ''
}

export const getReviewStatusOptions = (): Category[] => {
    const pendingTranslationOption = {
        name: 'document.pendingTranslation' as string,
        id: DocumentStatus.PendingTranslation,
        children: [],
    };
    const verifiedOption = {
        name: 'document.verified' as string,
        id: DocumentStatus.Verified,
        children: [],
    };
    
    if (!isSuperAdmin() && !isContentReviewer()) {
        return [pendingTranslationOption, verifiedOption];
    }

    return [
        {
            name: 'document.draft' as string,
            id: DocumentStatus.Draft,
            children: [],
        },
        {
            name: 'document.publishFilter' as string,
            id: DocumentStatus.Published,
            children: [],
        },
        { ...pendingTranslationOption },
        {
            name: 'document.notRelevent' as string,
            id: DocumentStatus.NotRelevant,
            children: [],
        },
        { ...verifiedOption },
    ];
}

const initializeFilter = (filterKey: string, filter?: number | string | (number | string)[]): any => {
    const stringFilters = [FILTERS.SEVERITY, FILTERS.RISK, FILTERS.REVIEWSTATUS, FILTERS.EDITOR];

    if (!filter)
        return [];

    let filterList = isArray(filter) ? filter : [filter];

    if (!stringFilters.includes(filterKey)) {
        filterList = filterList.map((f) => isNaN(Number(f)) ? f : Number(f));
    }

    return filterList
}

export const initializeFilters = (filters?: GetDocumentsQuery | any): GetDocumentsQuery => {
    return {
        page: filters?.page ? Number(filters?.page) : 1,
        Severity: initializeFilter(FILTERS.SEVERITY, filters?.Severity),
        Risk: initializeFilter(FILTERS.RISK, filters?.Risk),
        Hazard: initializeFilter(FILTERS.HAZARD, filters?.Hazard),
        Location: initializeFilter(FILTERS.LOCATION, filters?.Location),
        Consequence: initializeFilter(FILTERS.CONSEQUENCE, filters?.Consequence),
        Category: initializeFilter(FILTERS.CATEGORY, filters?.Category),
        Fleet: initializeFilter(FILTERS.FLEET, filters?.Fleet),
        Editor: initializeFilter(FILTERS.EDITOR, filters?.Editor),
        ReviewStatus: initializeFilter(FILTERS.REVIEWSTATUS, filters?.ReviewStatus),
        Sort: filters?.Sort ?? 'Date',
    };
}

export const numberOfAppliedFilters = (filters: any): number => {

    let total = 0;

    for (const key of filtersToCount) {
        if (filters[key]) {
            //for filters that are strings only and not arrays it would get the length of text if not put in an array
            const filter = isArray(filters[key]) ? filters[key] : [filters[key]];
            total += filter?.length;
        }
    }
    return total;
}

const getNumericValuesOfRiskAndSeverityFilter = (options: string[]): number[] => {
    if (!options?.length)
        return [];
    return [...options].map((key: string) => SeverityAndRisk[key]);
}

const setFilterAsUserPreference = (filters: Filters): Filters => {
    return {
        Severity: getNumericValuesOfRiskAndSeverityFilter(
            initializeFilter(FILTERS.SEVERITY, filters?.Severity)
        ),
        Risk: getNumericValuesOfRiskAndSeverityFilter(
            initializeFilter(FILTERS.RISK, filters?.Risk)
        ),
        Hazard: initializeFilter(FILTERS.HAZARD, filters?.Hazard),
        Location: initializeFilter(FILTERS.LOCATION, filters?.Location).map((option: string | number) => {
            return `${option}`
        }),
        Consequence: initializeFilter(FILTERS.CONSEQUENCE, filters?.Consequence),
        Category: initializeFilter(FILTERS.CATEGORY, filters?.Category),
        Fleet: initializeFilter(FILTERS.FLEET, filters?.Fleet),
    }
}

export const getStringifiedFilters = (filters: Filters): string => {
    return JSON.stringify(setFilterAsUserPreference(filters));
}

export const capitalizeFilters = (obj: any): any => {
    const capitalizedObject: any = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const capitalizedKey = startCase(key).replaceAll(' ','');
            capitalizedObject[capitalizedKey] = obj[key];
        }
    }
    return capitalizedObject;
}

export const getAllAppliedFilterKeys = (filters: any): string[] => {
    if (!filters) return []

    return Object.keys(filters).filter(
        (option) => Object.values(filters[option]).length > 0 &&
        filtersToCount.includes(option) &&
        option !== FILTERS.EDITOR &&
        option !== FILTERS.REVIEWSTATUS
    )
}
