<template>
    <div
        class="filters-container flex flex-col"
    >
        <div>
            <pui-tabs
                :id="tabsContainerId"
                :selected="selectedTabIndex"
                @changed="onTabChanged"
            >
                <pui-action-bar
                    v-if="$route.name === 'Settings'"
                    :actions="actionItems"
                    :is-inline="true"
                    slot="action-bar"
                    @clicked:action="handleActionClicked"
                />
                <pui-tab
                    :id="tabId"
                    class="tab mt-1"
                    v-for="filter in multiCheckFilters"
                    :key="filter.name"
                    :title="`${filter.label} (${getSelectedOptionsForFilter(filter.name).length})`"
                >
                    <multi-check-tree
                        :data-cy="`filter${filter.name}`"
                        :key="`filter-${filter.name}`"
                        :open="filter.open"
                        :header="filter.label"
                        :filter-property="filter.name"
                        :show-search="filter.appliedOptions.length > 5"
                        :options="filter.options"
                        :selected="getSelectedOptionsForFilter(filter.name)"
                        :has-check-boxes-on-the-right="false"
                        @update="handleMultiCheckUpdate"
                    />
                </pui-tab>
            </pui-tabs>
            <pui-button
                v-if="showScrollTopButton"
                id="scrollToTopBtn"
                class="scroll-top-btn"
                v-pui-tooltip="{message: $t('actions.scrollToTop')}"
                :icon-only="true"
                icon="up"
                @click="scrollFilterOnTop"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import MultiCheckTree from '@/components/filters/multi-check-tree.vue';
import { Category, IdToItemMap, MultiCheckFilter, Profile } from '@/models';
import AppliedFilters from '@/components/filters/applied-filters.vue';
import {
    initializeFilters,
    riskAndSeverityOptions,
    riskOptions
} from '@/utils/filters-helper';

@Component({
    name: 'side-filters',
    components: { AppliedFilters, MultiCheckTree },
    computed: {
        ...mapState('document', ['editors']),
        ...mapState('category', ['categories']),
        ...mapState('location', ['locations']),
        ...mapState('fleet', ['fleets']),
        ...mapState('hazard', ['hazards']),
        ...mapState('consequence', ['consequences']),
        ...mapGetters({
            CATEGORIES_BY_ID: 'category/CATEGORIES_BY_ID',
            LOCATIONS_BY_ID: 'location/LOCATIONS_BY_ID',
            FLEETS_BY_ID: 'fleet/FLEETS_BY_ID',
            HAZARDS_BY_ID: 'hazard/HAZARDS_BY_ID',
            FILTERS: 'filters/FILTERS',
        }),
    },
})
export default class SettingsFilters extends Vue {
    private categories!: Category[];
    private readonly CATEGORIES_BY_ID!: IdToItemMap<Category>;
    private locations!: Category[];
    private readonly LOCATIONS_BY_ID!: IdToItemMap<Category>;
    private fleets!: Category[];
    private readonly FLEETS_BY_ID!: IdToItemMap<Category>;
    private hazards!: Category[];
    private readonly HAZARDS_BY_ID!: IdToItemMap<Category>;
    private consequences!: Category[];
    private readonly editors!: Profile[];
    private readonly FILTERS!: any;
    private selectedTabIndex = 0;
    private currentlySelectedFilter = 'Category';
    private removeScrollHandler: (() => void) | null = null;
    private tabsContainerObserver: MutationObserver | null = null;
    private showScrollTopButton = false;
    private readonly tabId = 'tab';
    private readonly tabsContainerId = 'tabsContainer';

    private riskAndSeverityOptions = this.mapTranslatedOptions(Object.values(riskAndSeverityOptions));
    private riskOptions = this.mapTranslatedOptions(Object.values(riskOptions));

    private updateFiltersAction = 'filters/UPDATE_FILTERS_LOCALLY';

    private get actionItems(): any[] {
        return  [
            {
                name: 'clearSelected',
                displayName: `${this.$t('actions.clear')} ${this.currentlySelectedFilter}
                    ${this.$t('settings.selection')}`,
                iconName: 'reset',
                isPrimary: true,
                enabled: this.getSelectedOptionsForFilter(this.currentlySelectedFilter).length > 0
            },
        ]
    }

    private handleActionClicked(): void {
        const filterProperty = this.multiCheckFilters[this.selectedTabIndex].name;
        const emptyFilter = {[filterProperty]: []};
        this.handleMultiCheckUpdate(emptyFilter);
    }

    private bindScrollListener(): void {
        this.removeScrollHandler?.();
        const tab = document.getElementById(this.tabId);
        if (!tab) {
            return;
        }

        const handleScroll = (): void => {
            this.showScrollTopButton = tab.scrollTop > 0;
        }
        tab.addEventListener('scroll', handleScroll);
        this.removeScrollHandler = (): void =>{
            tab.removeEventListener('scroll', handleScroll);
            this.showScrollTopButton = false;
        }
    }

    private onTabChanged(tabIndex: number): void {
        this.selectedTabIndex = tabIndex;
        this.currentlySelectedFilter = this.multiCheckFilters[tabIndex].name;
    }

    private mapTranslatedOptions(list: Category[]): Category[] {
        return list.map((item) => {
            return {
                ...item,
                name: this.$tc(item.name)
            };
        });
    }

    private query: any = {};

    private mounted(): void {
        this.$nextTick(() => {
            this.bindScrollListener();
            this.observeTabsContainerChange();
        });
        this.query = {...this.FILTERS};
    }

    private beforeDestroy(): void {
        this.removeScrollHandler?.();
        this.tabsContainerObserver?.disconnect();
    }

    private observeTabsContainerChange(): void {
        const targetNode = document.getElementById(this.tabsContainerId);

        if (!targetNode) {
            return;
        }

        const callback = (): void => this.bindScrollListener();
        this.tabsContainerObserver = new MutationObserver(callback);
        this.tabsContainerObserver.observe(targetNode, { childList: true });
    }

    private get multiCheckFilters(): MultiCheckFilter[] {
        return [
            {
                label: this.$tc('document.category'),
                name: 'Category',
                options: this.categories,
                open: false,
                appliedOptions: Object.values(this.CATEGORIES_BY_ID),
            },
            {
                label: this.$tc('document.location'),
                name: 'Location',
                options: this.locations,
                open: false,
                appliedOptions: Object.values(this.LOCATIONS_BY_ID),
            },
            {
                label: this.$tc('document.fleet'),
                name: 'Fleet',
                options: this.fleets,
                open: false,
                appliedOptions: Object.values(this.FLEETS_BY_ID),
            },
            {
                label: this.$tc('document.hazard'),
                name: 'Hazard',
                options: this.hazards.filter(hazard => hazard.id !== 0 && hazard.id !== -1),
                open: false,
                appliedOptions: Object.values(this.HAZARDS_BY_ID),
            },
            {
                label: this.$tc('document.consequence'),
                name: 'Consequence',
                options: this.consequences.filter(cons => cons.id !== 1 && cons.id !== -1),
                open: false,
                appliedOptions: this.consequences,
            },
            {
                label: this.$tc('document.severity'),
                name: 'Severity',
                options: this.riskAndSeverityOptions,
                open: true,
                appliedOptions: this.riskAndSeverityOptions,
            },
            {
                label: this.$tc('document.risk'),
                name: 'Risk',
                options: this.riskOptions,
                open: true,
                appliedOptions: this.riskOptions,
            },
        ];
    }

    private async applyFilters(): Promise<void> {
        this.$store.dispatch(this.updateFiltersAction, initializeFilters(this.query));
    }

    private handleMultiCheckUpdate(value: any): void {
        const key: any = Object.keys(value)[0];
        this.query = {
            ...this.query,
            [key]: Object.values(value[key])
        };
        this.applyFilters();
    }

    private handleClearFilter(filter: string): void {
        this.query = {
            ...this.query,
            [filter]: []
        };
        this.applyFilters();
    }

    @Watch('FILTERS')
    onStoreFiltersChanged(): void {
        this.query = {...this.FILTERS};
    }

    private getSelectedOptionsForFilter(filterName: string): any {
        return this.FILTERS[filterName];
    }

    private scrollFilterOnTop(): void {
        const el = document.getElementById(this.tabId);
        if (!el) {
            return;
        }

        el.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';
.filters-container {
    height: 100%;
    position: relative;
}

.tab {
    position: relative;
    max-height: 40rem;
    height: min-content;
    overflow-y: auto;
    border: 1px solid #eceaea;
    border-radius: 0.5rem;
}

.scroll-top-btn {
    position: absolute;
    bottom: 1rem;
    right: 3rem;
}

.pui-tabs {
    /deep/ .pui-action-bar-item:hover {
        background-color: unset;
    }
}
</style>
