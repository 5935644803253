import { GetDocumentsQuery } from '@/models';

export const formatDateTime = (dateString?: string): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    if (date) {
        const { format } = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false,
        });
        return format(date);
    } else {
        return 'n/a';
    }
};

export const formatDate = (dateString: string): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    if (date) {
        const { format } = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric', month: 'numeric', day: 'numeric',
        });
        return format(date);
    } else {
        return 'n/a';
    }
};

export const getFormattedDate = (dateString: string | undefined): string => {
    if (!dateString) {
        return '';
    }
    if (dateString.slice(-8) === '00:00:00') {
        return formatDate(dateString);
    } else {
        return formatDateTime(dateString);
    }
}

export const formatUrlSearchParams = (query: GetDocumentsQuery): URLSearchParams => {
    const qs = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val) => qs.append(key, val));
        } else {
            qs.append(key, value);
        }
    });
    return qs;
};
